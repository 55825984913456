exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-blank-page-index-tsx": () => import("./../../../src/pages/BlankPage/index.tsx" /* webpackChunkName: "component---src-pages-blank-page-index-tsx" */),
  "component---src-pages-image-editor-index-tsx": () => import("./../../../src/pages/ImageEditor/index.tsx" /* webpackChunkName: "component---src-pages-image-editor-index-tsx" */),
  "component---src-pages-image-parser-index-tsx": () => import("./../../../src/pages/ImageParser/index.tsx" /* webpackChunkName: "component---src-pages-image-parser-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-paint-index-tsx": () => import("./../../../src/pages/Paint/index.tsx" /* webpackChunkName: "component---src-pages-paint-index-tsx" */),
  "component---src-pages-text-editor-index-tsx": () => import("./../../../src/pages/TextEditor/index.tsx" /* webpackChunkName: "component---src-pages-text-editor-index-tsx" */)
}

